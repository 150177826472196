import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from "src/core/session/domain/session.service";
import { NavigationService } from "src/core/navigation/domain/navigation.service";

export const redirectIfLoggedGuard: CanActivateFn = async () => {
  const sessionService = inject(SessionService);
  const navigationService = inject(NavigationService);

  const logged = await sessionService.isAuthenticatedPromise();
  if (logged) {
    await navigationService.gotoRootPage();
    return false;
  } else {
    return true;
  }
};
