import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Location } from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly DEFAULT_ROUTE = '/tabs/home';
  private readonly REWARDS_SEGMENT_PRIZES = 'prizes';
  private readonly REWARDS_SEGMENT_MISSIONS = 'missions';
  private lastTabUrl: string = this.DEFAULT_ROUTE;

  constructor(
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly location: Location,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.startsWith('/tabs/')) {
          this.lastTabUrl = event.url;
        }
      }
    });
  }

  getLastTabUrl(): string {
    return this.lastTabUrl;
  }

  async gotoRootPage() {
    await this.router.navigate([ '/' ]);
  }

  async gotoWelcome() {
    await this.router.navigate([ '/welcome' ]);
  }

  async gotoWizardPage() {
    await this.router.navigate([ '/wizard' ]);
  }

  async gotoTabs() {
    await this.router.navigate([ '/tabs' ]);
  }

  async gotoProfile() {
    await this.router.navigate([ '/profile' ]);
  }

  async gotoEditProfile() {
    await this.router.navigate([ '/profile/edit' ]);
  }

  async gotoMissionInHomePage(missionId: string) {
    await this.router.navigate([ `/tabs/home/missions/${missionId}` ]);
  }

  async gotoRewardsPage() {
    await this.router.navigate([ `/tabs/rewards` ]);
  }

  isInRewardSegment(segment: string): boolean {
    return this.router.url !== `/tabs/rewards/${segment}`;
  }

  async selectRewardSegment(segment: string) {
    this.location.replaceState(`/tabs/rewards/${segment}`);
    await this.storage.set('rewards.segment', segment);
  }

  async gotoPrizesPage() {
    await this.storage.set('rewards.segment', this.REWARDS_SEGMENT_PRIZES);
    await this.router.navigate([ `/tabs/rewards/${this.REWARDS_SEGMENT_PRIZES}` ]);
  }

  async gotoMissionsPage() {
    await this.storage.set('rewards.segment', this.REWARDS_SEGMENT_MISSIONS);
    await this.router.navigate([ `/tabs/rewards/${this.REWARDS_SEGMENT_MISSIONS}` ]);
  }

  async gotoMissionPage(missionId: string) {
    await this.router.navigate([ `/tabs/rewards/missions/${missionId}` ]);
  }

  async gotoPrizePage(prizeId: string) {
    await this.router.navigate([ `/tabs/rewards/prizes/${prizeId}` ]);
  }

  async gotoAudioGuidePage() {
    await this.router.navigate([ `/tabs/audioguide` ]);
  }

  async gotoExperiencePage(experienceId: string) {
    await this.router.navigate([ `/tabs/audioguide/${experienceId}` ]);
  }

  async gotoTicketsPage() {
    await this.router.navigate([ `/tabs/tickets` ]);
  }

  async gotoAddTicketsPage() {
    await this.router.navigate([ '/add-tickets' ]);
  }

  async gotoTicketPage(ticketId: string, reservationId: string) {
    await this.router.navigate([ `/tabs/tickets/${ticketId}/${reservationId}` ]);
  }
}
