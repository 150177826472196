import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GetAccessToken } from "src/core/authentication/application/get-access-token/get-access.token";
import { InitializerService } from "src/core/shared/initializer/domain/initializer.service";
import { ApiService } from "src/core/api/domain/api.service";
import { NavigationService } from "src/core/navigation/domain/navigation.service";
import { GetTickets } from "src/core/ticket/application/get/get-tickets";

export const checkAuthorizationCodeInUrlGuard: CanActivateFn = async () => {
  const navigationService = inject(NavigationService);
  const getAccessToken = inject(GetAccessToken);
  const apiService = inject(ApiService);
  const getTickets = inject(GetTickets);

  const url = window.location.href;
  const codeMatch = url.match(/code=([^&]+)/);
  if (!codeMatch) {
    return true;
  }

  const authorizationCode = codeMatch[1];
  // console.log(`Found Authorization Code in URL <${authorizationCode}>, storing in local database`);

  await InitializerService.waitFor(apiService);
  await getAccessToken.execute(authorizationCode);

  let userHasTickets;
  try {
    const timeout = setTimeout(() => {
      throw new Error('TIMEOUT');
    }, 10_000);
    const tickets = await getTickets.execute();
    if (timeout) {
      clearTimeout(timeout);
    }
    userHasTickets = tickets.toArray().length > 0;
  } catch (exception) {
    userHasTickets = true;
  }

  if (userHasTickets) {
    await navigationService.gotoWizardPage();
  } else {
    await navigationService.gotoAddTicketsPage();
  }

  return true;
};

