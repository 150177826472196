import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { Storable } from "src/core/shared/storage/domain/storable";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Injectable } from "@angular/core";
import { Steps } from "src/core/step/domain/steps";
import { Step, StepPrimitives } from "src/core/step/domain/step";
import { ExperienceMetadata } from "src/core/step/domain/experience-metadata";
import { Nullable } from "src/core/shared/types/nullable.type";

@Injectable({
  providedIn: 'root',
})
export class StepService implements Storable, Initializable {
  private readonly KEY_PREFIX = 'experience';
  private readonly KEY_METADATA_PREFIX = 'experience_metadata_';

  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  constructor(private storage: StorageService) {}

  async init() {
    this.readySubject.next(true);
  }

  async clear() {
    await this.clearAllMetadata();
    await this.clearAllSteps();
  }

  async getSteps(experienceId: string, language: string): Promise<Steps> {
    const key = this.keyFor(experienceId, language);
    const steps = await this.storage.get(key);
    return new Steps(steps.map((step: StepPrimitives) => Step.fromPrimitives(step)));
  }


  async getStep(stepId: string, experienceId: string, language: string): Promise<Nullable<Step>> {
    const key = this.keyFor(experienceId, language);
    const steps = await this.storage.get(key);
    for (let step of steps) {
      if (step.id === stepId) {
        return Step.fromPrimitives(step);
      }
    }

    return null;
  }

  async getMetadata(experienceId: string, language: string): Promise<ExperienceMetadata> {
    const key = this.keyForMetadata(experienceId, language);
    const experienceMetadata = await this.storage.get(key);
    if (!experienceMetadata) {
      return ExperienceMetadata.new(experienceId, language);
    }

    return new ExperienceMetadata(experienceMetadata.experienceId, experienceMetadata.language, experienceMetadata.metadata);
  }

  async saveSteps(experienceId: string, language: string, steps: Steps) {
    const stepsPrimitives = steps.toPrimitives();
    const key = this.keyFor(experienceId, language);
    await this.storage.set(key, stepsPrimitives);
  }

  async saveStep(experienceId: string, language: string, step: Step) {
    const key = this.keyFor(experienceId, language);
    const stepsPrimitives = await this.storage.get(key);

    const index = stepsPrimitives.findIndex((stepPrimitives: StepPrimitives) => stepPrimitives.id === step.id);
    if (index === -1) {
      return;
    }

    stepsPrimitives[index] = step.toPrimitives()
    await this.storage.set(key, stepsPrimitives);
  }

  async saveMetadata(metadata: ExperienceMetadata) {
    const experiencesMetadataPrimitives = metadata.toPrimitives();
    const key = this.keyForMetadata(metadata.experienceId, metadata.language);
    await this.storage.set(key, experiencesMetadataPrimitives);
  }

  async isStored(experienceId: string, language: string): Promise<boolean> {
    const key = this.keyFor(experienceId, language);
    const steps = await this.storage.get(key);
    return steps !== null;
  }

  async deleteExperience(experienceId: string) {
    await this.deleteExperienceSteps(experienceId);
    await this.deleteExperienceMetadata(experienceId);
  }

  private async deleteExperienceSteps(experienceId: string) {
    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(`${this.KEY_PREFIX}__${experienceId}`)) {
        await this.storage.remove(key);
      }
    }
  }

  private async deleteExperienceMetadata(experienceId: string) {
    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(`${this.KEY_METADATA_PREFIX}__${experienceId}`)) {
        await this.storage.remove(key);
      }
    }
  }

  private keyFor(experienceId: string, language: string) {
    return `${this.KEY_PREFIX}__${experienceId}__${language}`;
  }

  private keyForMetadata(experienceId: string, language: string) {
    return `${this.KEY_METADATA_PREFIX}__${experienceId}__${language}`;
  }

  private async clearAllSteps() {
    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(`${this.KEY_PREFIX}__`)) {
        await this.storage.remove(key);
      }
    }
  }

  private async clearAllMetadata() {
    const keys = await this.storage.keys();
    for (const key of keys) {
      if (key.startsWith(`${this.KEY_METADATA_PREFIX}__`)) {
        await this.storage.remove(key);
      }
    }
  }
}
