import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SessionService } from "src/core/session/domain/session.service";
import { NavigationService } from "src/core/navigation/domain/navigation.service";

export const authGuard: CanActivateFn = async () => {
  const sessionService = inject(SessionService);
  const navigationService = inject(NavigationService);

  const isLogged = await sessionService.isAuthenticatedPromise();
  if (isLogged) {
    return true;
  } else {
    await navigationService.gotoWelcome();
    return false;
  }
};
