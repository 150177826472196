import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { last, lastValueFrom, tap } from "rxjs";
import { SessionService } from "../../session/domain/session.service";
import { StepRepository } from "src/core/step/domain/step.repository";
import { Step } from "src/core/step/domain/step";
import { ApiGetStepResponse } from "src/core/step/infrastructure/api-get-step-response";
import { filter } from "rxjs/operators";
import { StepMedia } from "src/core/step/domain/step-media";
import { language } from "ionicons/icons";
import { LocalDate } from "src/core/shared/date/local-date";
import { StepImage } from "src/core/step/domain/step-image";
import { StepHasNoMediaException } from "src/core/step/domain/step-has-no-media.exception";
import { ApiService } from "src/core/api/domain/api.service";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";

@Injectable({
  providedIn: 'any',
})
export class HttpStepRepository extends StepRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {
    super();
  }

  async load(experienceId: string, stepId: string, language: string): Promise<Step> {

    return new Promise(async (resolve, reject) => {
      try {
        const apiResponse = await this.loadResponse(stepId, language);
        resolve(this.parseApiLoadResponse(apiResponse, experienceId));
      } catch (exception) {
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async loadMedia(url: string, stepId: string, language: string, progressCallback: (stepId: string, percentage: number) => void): Promise<StepMedia> {
    const options = {
      responseType: 'blob' as 'json',
      reportProgress: true,
      observe: 'events',
    };
    const req = new HttpRequest('GET', url, options);
    const response: HttpResponse<Blob> = await lastValueFrom(
      this.http.request<Blob>(req).pipe(
        tap((event: HttpEvent<Blob>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              progressCallback(stepId, 0);
              break;
            case HttpEventType.DownloadProgress:
              const percentDone = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
              progressCallback(stepId, percentDone);
              break;
            case HttpEventType.Response:
              progressCallback(stepId, 100);
              break;
          }
        }),
        filter((event: HttpEvent<Blob>): event is HttpResponse<Blob> => event instanceof HttpResponse),
        last(),
      ),
    );

    if (response.type !== HttpEventType.Response) {
      throw new Error('Unexpected response type');
    }

    return new StepMedia(stepId, url, language, response.body as Blob);
  }

  async loadImage(url: string, stepId: string): Promise<StepImage> {
    const options = {
      responseType: 'blob' as 'json',
      reportProgress: true,
      observe: 'events',
    };
    const req = new HttpRequest('GET', url, options);
    const response: HttpResponse<Blob> = await lastValueFrom(
      this.http.request<Blob>(req).pipe(
        tap((event: HttpEvent<Blob>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.DownloadProgress:
              break;
            case HttpEventType.Response:
              break;
          }
        }),
        filter((event: HttpEvent<Blob>): event is HttpResponse<Blob> => event instanceof HttpResponse),
        last(),
      ),
    );

    if (response.type !== HttpEventType.Response) {
      throw new Error('Unexpected response type');
    }

    return new StepImage(stepId, url, response.body as Blob);
  }

  private async loadResponseMocked(stepId: string, language: string): Promise<ApiGetStepResponse> {
    return await lastValueFrom(this.http.get('/assets/api-mocked/get-step.response.json')) as ApiGetStepResponse;
  }

  private async loadResponse(stepId: string, language: string): Promise<ApiGetStepResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const url = `${baseUrl}/steps/${stepId}/${language}`;
    const apiToken = this.sessionService.getApiToken();
    const apiVersion = '1.0';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    let httpParams = new HttpParams();
    // Uncomment next line to use fakeData (used if API real content is deleted)
    // httpParams = httpParams.set('fakeData', 'true');
    const options = {
      params: httpParams,
      headers: httpHeaders,
    };

    return await lastValueFrom(this.http.get<ApiGetStepResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetStepResponse, experienceId: string): Step {
    const stepData = response.step;
    const imageUrl = response.step.imageUrl ?? this.defaultImage();
    const firstContent = stepData.contents[0];
    const contentOfLanguage = firstContent.data.filter(datum => datum.language === language);
    const firstContentOfLanguage = contentOfLanguage.length > 0
      ? contentOfLanguage[0]
      : firstContent.data[0];

    if (!firstContentOfLanguage || !firstContentOfLanguage.url) {
      throw new StepHasNoMediaException();
    }

    const name = stepData.name['es'] ?? '--';
    const roomName = stepData.roomName['es'] ?? '--';
    const floorName = stepData.floorName['es'] ?? '--';
    return new Step(
      stepData.id,
      experienceId,
      stepData.language,
      stepData.order,
      name,
      stepData.duration,
      roomName,
      floorName,
      firstContentOfLanguage.url,
      imageUrl,
      LocalDate.fromIsoString(stepData.updatedAt),
      stepData.missionId,
    );
  }

  private defaultImage(): string {
    return ((Math.random() * 10) > 5)
      ? 'https://www.rideosoftware.com/temp/gpa-1.jpg'
      : 'https://www.rideosoftware.com/temp/gpa-2.jpg';
  }
}
