import { Nullable } from "src/core/shared/types/nullable.type";

export type MetadataPrimitives = {
  isCompleted: boolean;
  steps: Array<StepMetadata>
}

export type StepMetadata = {
  stepId: string;
  percentage: number;
  isDownloaded: boolean;
  isCompleted: boolean;
  isSelected: boolean;
};

export class ExperienceMetadata {
  constructor(public readonly experienceId: string, public readonly language: string, public metadata: MetadataPrimitives) {}

  static new(experienceId: string, language: string) {
    return new this(experienceId, language, { steps: [], isCompleted: false } as MetadataPrimitives);
  }

  toPrimitives() {
    return {
      experienceId: this.experienceId,
      language: this.language,
      metadata: this.metadata,
    }
  }

  recordCompleted(): void {
    this.metadata.isCompleted = true;
  }

  isCompleted(): boolean {
    return this.metadata.isCompleted;
  }

  recordStepProgress(stepId: string, percentage: number) {
    const index = this.metadata.steps.findIndex(step => step.stepId === stepId);
    if (index !== -1) {
      this.metadata.steps[index].percentage = percentage;
      this.metadata.steps[index].isDownloaded = percentage === 100;
    } else {
      let stepMetadata = this.defaultStepMetadata(stepId);
      stepMetadata.percentage = percentage
      stepMetadata.isDownloaded = percentage === 100;
      this.metadata.steps.push(stepMetadata);
    }
  }

  recordStepSelected(stepId: Nullable<string>) {
    this.metadata.steps.map(step => step.isSelected = false);
    if (stepId !== null) {
      const index = this.metadata.steps.findIndex(step => step.stepId === stepId);
      if (index !== -1) {
        this.metadata.steps[index].isSelected = true;
      }
    }
  }

  recordStepCompleted(stepId: Nullable<string>) {
    this.metadata.steps.map(step => step.isSelected = false);
    if (stepId !== null) {
      const index = this.metadata.steps.findIndex(step => step.stepId === stepId);
      if (index !== -1) {
        this.metadata.steps[index].isCompleted = true;
      }
    }
  }

  getSelectedStepId(): Nullable<string> {
    const selectedStep = this.metadata.steps.find(step => step.isSelected);
    if (!selectedStep) {
      return null;
    }

    return selectedStep.stepId;
  }

  isStepLoaded(stepId: string): boolean {
    const stepMetadata = this.metadata.steps.find(step => step.stepId === stepId);
    if (!stepMetadata) {
      return false;
    }

    return stepMetadata.isDownloaded;
  }

  isStepCompleted(stepId: string): boolean {
    const stepMetadata = this.metadata.steps.find(step => step.stepId === stepId);
    if (!stepMetadata) {
      return false;
    }

    return stepMetadata.isCompleted;
  }

  allStepsCompleted(): boolean {
    return this.metadata.steps.every(step => step.isCompleted);
  }

  private defaultStepMetadata(stepId: string) {
    return {
      stepId,
      percentage: 0,
      isCompleted: false,
      isDownloaded: false,
      isSelected: false,
    };
  }
}
